import React                from 'react';
import { 
    Switch, 
    Route 
}                           from 'react-router-dom';

import ContextProvider      from '../../contexts/ContextProvider';

import '../../assets/styles/base.scss';

const Index             = React.lazy(() => import('../../scenes/Index'));

export default function App() {
    return (
        <ContextProvider>
            <React.Suspense fallback={null}>
                <Switch basename={'/'}>
                    <Route component={Index} path={`${process.env.PUBLIC_URL}/`} exact />
                </Switch>
            </React.Suspense>
        </ContextProvider>
    );
}
